import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { useMediaCenterBottomContent } from "../../../../hooks/useMediaCenter/useMediaCenterBottomContent";
import Spacer from "../../../Spacer/Spacer";
import IsrButton from "../../../IsrButton/IsrButton";

const MediaKit = () => {
  const {
    contactInfoMessage,
    informativeMessage,
    buttonLinkUrl: { title, url },
    logo,
  } = useMediaCenterBottomContent();

  return (
    <div className="container">
      <Spacer mobileSize={50} size={100} />
      <div className="mediaCenterEnquiries">
        <div className="upperPart">
          <div>
            {contactInfoMessage && (
              <div
                className="info-message"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: contactInfoMessage,
                }}
              />
            )}
            <br />
            {url && title && (
              <Link to={url}>
                <IsrButton type="button" variant="primary" size="md">
                  {title}
                </IsrButton>
              </Link>
            )}
          </div>
          <Link to="/">
            {logo && (
              <GatsbyImage
                className="NYAS-logo"
                image={logo.localFile.childImageSharp.gatsbyImageData}
                alt="NYA isr logo"
              />
            )}
          </Link>
        </div>
        {informativeMessage && (
          <div className="bottomPart">
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: informativeMessage,
              }}
            />
          </div>
        )}
      </div>
      <Spacer mobileSize={75} size={150} />
    </div>
  );
};

export default MediaKit;
