import { useStaticQuery, graphql } from "gatsby";
export const useMediaCenterTopContent = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(template: { templateName: { eq: "Page Media Center" } }) {
        mediaCenter {
          mediaCenterTopContent
        }
      }
    }
  `);
  return data.wpPage.mediaCenter;
};
