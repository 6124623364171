import { useStaticQuery, graphql } from "gatsby";
export const useMediaCenterBottomContent = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(template: { templateName: { eq: "Page Media Center" } }) {
        mediaCenter {
          mediaCenterBottomContent {
            contactInfoMessage
            informativeMessage
            buttonLinkUrl {
              title
              url
              target
            }
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(formats: WEBP)
                }
              }
            }
          }
        }
      }
    }
  `);
  return data.wpPage.mediaCenter.mediaCenterBottomContent;
};
