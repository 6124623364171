import React from "react";
import { useMediaCenterTopContent } from "../../../../hooks/useMediaCenter/useMediaCenterTopContent";
import Spacer from "../../../Spacer/Spacer";

const TopContent = () => {
  const { mediaCenterTopContent } = useMediaCenterTopContent();
  return mediaCenterTopContent ? (
    <div className="container">
      <Spacer mobileSize={50} size={100} />
      <div
        className="top-content"
        dangerouslySetInnerHTML={{
          __html: mediaCenterTopContent,
        }}
      />
    </div>
  ) : null;
};

export default TopContent;
