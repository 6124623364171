import { useStaticQuery, graphql } from "gatsby";
export const useMediaCenterHeadingSection = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(template: { templateName: { eq: "Page Media Center" } }) {
        mediaCenter {
          mediaCenterHeadingSection {
            heading
            backgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(formats: WEBP, height: 300, width: 1440)
                }
              }
            }
          }
        }
      }
    }
  `);
  return data.wpPage.mediaCenter.mediaCenterHeadingSection;
};
