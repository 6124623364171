import React from "react";
import Jumbotron from "../Jumbotron/Jumbotron";
import { useMediaCenterHeadingSection } from "../../hooks/useMediaCenter/useMediaCenterHeadingSection";
import "./MediaCenterSection.scss";
import TopContent from "./components/TopContent/TopContent";
import MediaKit from "./components/MediaKit/MediaKit";
// import SpokesPeopleSection from "./components/SpokesPeopleSection/SpokesPeopleSection";

const MediaCenterSection = () => {
  const { heading, backgroundImage } = useMediaCenterHeadingSection();

  return (
    <div className="mediaCenterSection">
      <Jumbotron media={backgroundImage} title={heading} />
      <TopContent />
      {/* <SpokesPeopleSection /> */}
      <MediaKit />
    </div>
  );
};

export default MediaCenterSection;
