import React from "react";
import Layout from "../components/layout/layout";
import Header from "../components/Header/Header";
import MediaCenterSection from "../components/MediaCenter/MediaCenterSection";
import Footer from "../components/Footer/Footer";

const MediaCenter = () => {
  return (
    <Layout>
      <Header />
      <MediaCenterSection />
      <Footer />
    </Layout>
  );
};

export default MediaCenter;
